import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.getToken()
      .pipe(
        map(token => {
          const isLoginUrl = state.url === '/login';
          if (token) {
            return isLoginUrl ? '/dashboard' : true;
          }
          return isLoginUrl ? true : '/login';
        }),
        map(result => typeof (result) === 'string' ? this.router.parseUrl(result) : result)
      );
  }

}
