import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error',
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss'],
})
export class ErrorPage {
  @Input() message: string;
  @Input() negativeButtonText?: string;
  @Input() positiveButtonText = 'Ok';

  constructor(public modalController: ModalController) {}
}
