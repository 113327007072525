import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

const AUTHORIZATION_TOKEN_CACHE_KEY = 'AUTHORIZATION_TOKEN';

interface TokenResponse {
  accessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private cache: CacheService,
    private http: HttpClient,
    private router: Router,
    private socialAuthService: SocialAuthService
  ) {}

  getToken(): Observable<string> {
    return this.cache.getOrSet<string>(AUTHORIZATION_TOKEN_CACHE_KEY, () =>
      this.socialAuthService.authState.pipe(
        switchMap((user) => {
          const payload = {
            email: user.email,
            idToken: user.idToken,
          };
          return this.http.post<TokenResponse>(
            `${environment.apiBaseUrl}/login`,
            payload
          );
        }),
        map((response) => response.accessToken),
        shareReplay(1),
        catchError(() => {
          this.cache.delete(AUTHORIZATION_TOKEN_CACHE_KEY);
          return of(null as string);
        })
      )
    );
  }

  async signOut(navigate: boolean = true) {
    try {
      await this.socialAuthService.signOut(true);
    } finally {
      this.cache.reset();

      if (navigate) {
        await this.router.navigate(['/login']);
      }
    }
  }
}
