import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export interface ExpirableCache<T> {
  expires: number;
  value$: Observable<T>;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: { [id: string]: ExpirableCache<any> };

  constructor() {
    this.reset();
  }

  getOrSet<T>(key: string, setFn: () => Observable<T>): Observable<T> {
    const entry = this.cache[key];
    if (entry) {
      if (entry.expires > Date.now()) {
        return entry.value$;
      }

      this.delete(key);
    }

    const value$ = setFn.apply(this);
    this.set(key, value$);
    return value$;
  }

  delete(key: string) {
    delete this.cache[key];
  }

  reset() {
    this.cache = {};
  }

  private set<T>(key: string, value$: Observable<T>): void {
    this.cache[key] = {
      expires: Date.now() + (1000 * environment.cacheLifespanSeconds),
      value$
    };
  }
}
