import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.endsWith('/login') || !req.url.startsWith(environment.apiBaseUrl)) {
            return next.handle(req);
        }

        return this.authService.getToken()
            .pipe(
                mergeMap(token => {
                    const nextRequest = token && req.url.startsWith(environment.apiBaseUrl) ? req.clone({
                        setHeaders: {
                            // eslint-disable-next-line quote-props,@typescript-eslint/naming-convention
                            'Authorization': `Bearer ${token}`
                        }
                    }) : req;

                    return next.handle(nextRequest)
                        .pipe(
                            catchError((error: HttpErrorResponse) => {
                                if (error?.status === 401) {
                                    this.authService.signOut();
                                }

                                return throwError(error);
                            })
                        );
                })
            );
    }
}
