import { Injectable } from '@angular/core';
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CacheService } from '../cache.service';

export const INVALIDATE_CACHE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(
        private cache: CacheService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        const cacheKey = req.urlWithParams;
        if (req.context.get(INVALIDATE_CACHE) === true) {
            this.cache.delete(cacheKey);
        }

        return this.cache.getOrSet(cacheKey, () => next.handle(req).pipe(shareReplay(1)));
    }
}
